.animated {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      rgba(239 245 255/ 0) 0%,
      #e0ebff 50.52%,
      rgba(239 245 255/ 0) 100%
    );
    animation: loading 1.5s infinite cubic-bezier(0.4, 0, 0.3, 1);

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
}

.main {
  display: flex;
  justify-content: center;
  position: relative;
}

.content {
  width: var(--content-width-monitor);
  display: flex;
  margin-top: 15px;

  .tablet & {
    margin-top: 0;
    width: var(--content-width-tablet);
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftColumn {
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  .tablet & {
    overflow: hidden;

    &.premierePage {
      flex: 1;
    }
  }
}

.item {
  border-radius: 5px;
  height: 16px;
  background-color: var(--color-alt-blue-1);
}

.pageTitle {
  width: 291px;
  height: 24px;

  &.wide {
    width: 340px;
  }

  &.short {
    width: 155px;
  }
}

.select {
  width: 150px;
  height: 40px;

  &.channelPage {
    width: 53px;
    height: 30px;
    border-radius: 15px;
  }
}

.navigation {
  height: 50px;
  border-radius: 50px;
  background-color: var(--color-alt-blue-1);
  margin-top: 15px;
}

.categories {
  display: flex;
  margin-top: 10px;

  & > div {
    width: 80px;
    height: 16px;
    margin-right: 20px;
  }
}

.channels {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -20px;

  .tablet & {
    margin: -30px 0 -75px -20px;
  }
}

.channelTitle {
  height: 19px;
  width: 180px;
  margin-bottom: 20px;
}

.channel {
  width: 300px;
  margin: 45px 0 0 20px;

  .tablet & {
    margin: 65px 0 0 20px;
  }
}

.channelRows {
  & div {
    width: 100%;
    height: 16px;

    & + div {
      margin-top: 9px;
    }

    &:nth-child(3n) {
      width: 225px;
    }
  }
}

.registration {
  width: 300px;
  height: 195px;
  border-radius: 15px;
  background-color: var(--color-alt-blue-1);
}

.news {
  width: 300px;
  height: 600px;
  background-color: var(--color-alt-blue-1);
  margin-top: 20px;

  &.short {
    height: 75px;
  }
}

.noMargin {
  margin: 0;
}

.channelInfo {
  margin-top: 20px;

  & div {
    width: 100%;

    &:nth-of-type(3) {
      width: 337px;
      margin-bottom: 5px;

      .tablet & {
        width: 285px;
      }
    }

    & + div {
      margin-top: 9px;
    }
  }
}

.time {
  width: 40px;
  margin-right: 5px;
}

.showTitle {
  width: 895px;

  .tablet & {
    width: 605px;
  }
}

.schedule {
  margin-top: 20px;
}

.scheduleRow {
  display: flex;
  margin-bottom: 15px;

  &:nth-of-type(3n) {
    & .showTitle {
      width: 600px;

      .tablet & {
        width: 490px;
      }
    }
  }
}

.show {
  padding: 20px 0;
  display: flex;
  border-bottom: 1px solid var(--color-alt-blue-2);
}

.showLeftColumn,
.showRightColumn {
  display: flex;
  flex-direction: column;
}

.showLeftColumn {
  width: 220px;
  margin-right: 20px;
}

.image {
  width: 220px;
  height: 125px;
}

.votes {
  margin-top: 10px;
  display: flex;

  & div {
    width: 55px;
    height: 30px;
    border-radius: 50px;

    &:nth-of-type(1) {
      margin-right: 10px;
    }
  }
}

.showCategories {
  margin: 10px 0 0 -10px;
  display: flex;
  flex-wrap: wrap;

  & div {
    height: 30px;
    border-radius: 25px;
    margin: 10px 0 0 10px;

    &:nth-of-type(1) {
      width: 95px;
    }

    &:nth-of-type(2) {
      width: 100px;
    }

    &:nth-of-type(3) {
      width: 80px;
    }

    &:nth-of-type(4) {
      width: 30px;
    }
  }
}

.text {
  & div {
    width: 700px;

    .tablet & {
      width: 730px;
    }

    & + div {
      margin-top: 10px;
    }

    &:last-of-type {
      width: 337px;
    }
  }
}

.showInfo {
  margin-top: 20px;
}

.showInfoRow {
  display: flex;

  & + .showInfoRow {
    margin-top: 15px;
  }

  & div:nth-of-type(1) {
    width: 100px;
    margin-right: 10px;
  }

  & div:nth-of-type(2) {
    width: 590px;
  }

  &:nth-of-type(3n) {
    & div:nth-of-type(2) {
      width: 495px;
    }
  }
}

.showSchedule {
  padding: 20px 0;
  border-bottom: 1px solid var(--color-alt-blue-2);
}

.showScheduleRow {
  display: flex;

  & + .showScheduleRow {
    margin-top: 15px;
  }

  & div + div {
    margin-left: 15px;
  }

  & div:nth-of-type(1) {
    width: 100px;
  }

  & div:nth-of-type(2) {
    width: 40px;
  }

  & div:nth-of-type(3) {
    width: 765px;
  }
}

.selection {
  padding: 30px 0 20px;
}

.selectionList {
  margin-top: 20px;
  display: flex;
}

.selectionItem {
  display: flex;
  flex-direction: column;

  & + .selectionItem {
    margin-left: 20px;
  }

  & .image {
    margin-bottom: 10px;
  }

  & div:nth-of-type(2) {
    width: 220px;
    margin-bottom: 10px;
  }

  & div:nth-of-type(3) {
    width: 130px;
  }
}

.premieres {
  padding: 20px 0;
  border-bottom: 1px solid var(--color-alt-blue-2);
  margin: -20px 0 20px;

  .tablet & {
    display: flex;
    flex-wrap: wrap;
    margin: -30px 0 -50px -20px;
    border-bottom: none;
  }
}

.premiereImage {
  width: 300px;
  height: 170px;

  .tablet & {
    width: 315px;
  }
}

.premiere {
  display: flex;
  margin-top: 20px;

  .tablet & {
    flex-direction: column;
    margin: 30px 0 0 20px;
  }
}

.premiereInfo {
  margin-left: 20px;

  .tablet & {
    margin-left: 0;
  }
}

.premiereRow {
  display: flex;
  align-items: flex-start;
  margin: 5px 0 10px;

  .tablet & {
    width: 255px;
    flex-wrap: wrap;
    margin: 15px 0 10px;

    & div:nth-of-type(1) {
      width: 100%;
    }

    & div:nth-of-type(2),
    & div:nth-of-type(3) {
      margin-top: 10px;
    }
  }

  & div:nth-of-type(2),
  & div:nth-of-type(3) {
    height: 30px;
    border-radius: 25px;
  }

  & div:nth-of-type(1) {
    height: 19px;
    width: 180px;
    margin-right: 20px;
  }

  & div:nth-of-type(2) {
    width: 98px;
    margin-right: 10px;
  }

  & div:nth-of-type(3) {
    width: 81px;
  }
}

.premiereDescription {
  & div {
    width: 620px;

    .tablet & {
      width: 315px;

      &:nth-of-type(3) {
        width: 315px;
      }

      &:nth-of-type(4) {
        width: 195px;
      }
    }

    & + div {
      margin-top: 10px;
    }

    &:nth-of-type(3) {
      width: 340px;
    }
  }
}

.premiereTime {
  display: flex;
  margin-top: 20px;

  & div {
    width: 100px;

    .tablet & {
      width: 70px;
    }

    & + div {
      margin-left: 20px;
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  & div {
    width: 165px;
    height: 40px;
    border-radius: 25px;
  }
}

.favoriteList {
  margin: 25px 0 35px;
  display: flex;

  &.last {
    margin: 25px 0 10px;

    .tablet & {
      margin: 25px 0 0;
    }
  }
}

.favoriteTitle {
  width: 130px;
  margin: 10px 0;
}

.favoriteItem + .favoriteItem {
  margin-left: 20px;
}

.favoriteSchedule {
  .tablet & {
    & div {
      height: 16px !important;
      width: 300px !important;
    }

    & div:nth-of-type(3n + 3) {
      width: 225px !important;
    }
  }

  & div + div {
    margin-top: 10px;
  }

  & div:nth-of-type(2n) {
    width: 300px;
  }

  & div:nth-of-type(2n + 1) {
    height: 11px;
    width: 85px;
  }
}

.favoriteItemTitle {
  width: 230px;
  height: 19px;
  margin-bottom: 15px;

  .tablet & {
    width: 180px;
  }
}

.newsListWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.newsTitle {
  height: 25px;
  width: 210px;
}

.newsList {
  margin: 15px 0 30px;
  display: flex;
  width: 100%;
}

.newsItem {
  width: 100%;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.newsImage {
  width: 100%;
  height: 125px;
  margin-bottom: 10px;
}

.newsText {
  height: 80px;
  margin-bottom: 5px;

  & > div {
    margin-bottom: 5px;

    &:nth-of-type(4) {
      width: 110px;
    }
  }
}

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}
