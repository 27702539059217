.main,
.billboardAd .banner,
.footerAd {
  width: var(--content-width-tablet);
  margin-left: auto;
  margin-right: auto;

  @media (--monitor) {
    width: var(--content-width-monitor);
  }
}

.main {
  padding-top: 20px;
  padding-bottom: 30px;
}

.billboardAd {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  background-color: var(--color-alt-blue-1);

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global .with-branding :local & {
    min-height: auto;
  }

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global .with-mini-billboard :local & {
    min-height: 100px;
  }
}

.content {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global .with-branding :local & {
    width: calc(var(--content-width-tablet) + 40px);
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    background-color: var(--color-white);

    @media (--monitor) {
      width: calc(var(--content-width-monitor) + 20px);
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
