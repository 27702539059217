@import url('https://static.rambler.ru/fonts/Manrope/Manrope.css');
@import url('./reset.css');
@import url('./variables.css');

html,
body {
  font-family: var(--manrope);
  text-size-adjust: none;
}

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  min-height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-moothing: grayscale;
  background-color: var(--color-white);
  color: var(--color-carbone-base);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  font-weight: 400;
}

header {
  line-height: normal;
}

pre,
code {
  font-family:
    SP Mono,
    Menlo,
    Monaco,
    Courier New,
    Courier,
    monospace;
  font-size: 0.95em;
  font-feature-settings: normal;
}

pre {
  overflow-x: auto;
}

a {
  text-decoration: none;
  color: var(--color-blue-base);
  transition: color 175ms;

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::-ms-clear {
  display: none;
}
