:root {
  /* transition */
  --transition-duration: 150ms;

  /* typeface */
  --manrope: Manrope, sans-serif;
  --montserrat: Montserrat, sans-serif;
  --font-size-x-small: 11px;
  --font-size-small: 13px;
  --font-size-medium: 14px;
  --font-size-default: 15px;
  --font-size-ml: 16px;
  --font-size-medium-s: 17px;
  --font-size-large: 18px;
  --font-size-extra-large: 21px;
  --font-weight-default: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --line-height-small: 15px;
  --line-height-default: 20px;
  --line-height-medium-s: 23px;
  --line-height-large: 25px;
  --line-height-extra-large: 30px;

  /* indentation */
  --mobile-horizontal-padding: 10px;

  /* content width */
  --content-width-tablet: 970px;
  --content-width-monitor: 1260px;

  /* colors */
  --color-white: #fff;
  --color-blue-base: #315efb;
  --color-blue-middle: #2c54e2;
  --color-blue-dark: #274bc8;
  --color-carbone-base: #343b4c;
  --color-carbone-dark: #1f2431;
  --color-carbone-ultra-dark: #0d1017;
  --color-light-carbone-base: #62687f;
  --color-light-carbone-1: #393f51;
  --color-light-carbone-2: #3d4456;
  --color-light-carbone-4: #464d60;
  --color-light-carbone-6: #50566b;
  --color-light-carbone-8: #595f75;
  --color-alt-blue-base: #649dff;
  --color-alt-blue-dark: #4a7dfd;
  --color-alt-blue-05: #f7faff;
  --color-alt-blue-1: #eff5ff;
  --color-alt-blue-2: #e0ebff;
  --color-alt-blue-3: #d1e2ff;
  --color-alt-blue-4: #c1d8ff;
  --color-alt-blue-6: #a2c4ff;
  --color-alt-blue-7: #92baff;
  --color-alt-blue-8: #83b1ff;
  --color-cloud-gray-base: #8d96b2;
  --color-cloud-gray-dark: #777e98;
  --color-cloud-gray-1: #f3f4f7;
  --color-cloud-gray-2: #e8eaf0;
  --color-cloud-gray-3: #dcdfe7;
  --color-cloud-gray-4: #d1d5e0;
  --color-cloud-gray-5: #c6cad8;
  --color-cloud-gray-7: #afb6c9;
  --color-green-base: #28bc00;
  --color-green-middle: #24a900;
  --color-green-dark: #209600;
  --color-green-1: #e9f8e5;
  --color-green-2: #d4f2cc;
  --color-yellow-base: #ffc000;
  --color-yellow-dark: #c90;
  --color-yellow-1: #fff8e5;
  --color-yellow-2: #fff2cc;
  --color-yellow-4: #ffe699;
  --color-yellow-7: #ffd24c;
  --color-red-base: #ff564e;
  --color-red-middle: #f2514a;
  --color-red-dark: #e54d46;
  --color-red-1: #ffeeed;
  --color-red-2: #ffdddc;
  --color-red-6: #ff9a95;
  --color-red-75: #ff857f;
  --color-red-85: #ff6f68;
  --color-dark-pink: #fc2772;
  --color-pink: #ffe9f1;
  --color-aquamarine-base: #00baad;
  --color-aquamarine-middle: #00a79b;
  --color-aquamarine-7: #4dcfc6;
  --color-aquamarine-85: #26c4b9;
  --color-sun-yellow-base: #fffbbf;
  --color-sky-blue-4: #d9f2ff;
  --color-vk-base: #07f;
  --color-ok-base: #ee8208;
  --color-tg-base: #2aaaed;
  --color-rss-base: #343b4c;
  --color-bg-blue: #1f47dd;
}
