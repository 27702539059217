.root {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  box-shadow: inset 0 -1px 0 0 var(--color-alt-blue-2);
}

.menu {
  position: relative;
  display: flex;
  min-width: var(--content-width-tablet);

  @media (--monitor) {
    width: var(--content-width-monitor);
  }
}

.menuHidden {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  width: 100%;
}

.list {
  display: flex;
}

.hiddenList {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  opacity: 0;
}

.link {
  padding: 10px 0;
  margin-right: 20px;
  color: var(--color-carbone-base);
  font-family: var(--manrope);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  transition: color var(--transition-duration);

  &:hover,
  &:focus {
    color: var(--color-blue-middle);
  }

  &.active {
    color: var(--color-blue-base);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.moreButton {
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  color: var(--color-carbone-base);
  font-family: var(--manrope);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-bold);

  &:hover,
  &:focus {
    color: var(--color-blue-middle);
  }

  span {
    margin-right: 5px;
  }
}

.dropdown {
  display: flex;
  align-items: center;
  padding: 10px 0;
  z-index: 3;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}
